<template>
    <!-- list-foot-bar 定义了列表页的底部工具栏的普遍结构样式的抽象，即：
        （见UI图）左侧为列表多选状态，右侧为分页组件。
        
        往组件里面插入元素时，默认插入右边，如果其slot属性为 "left" 会放
        入左边 -->
    <div class="list-foot-bar">
        <div class="left-wrapper">
            <div v-if="hasCheckedNum" class="mr3">已选{{ checkedNum }}条</div>
            <slot name="left"></slot>
        </div>
        <div><slot name="default"></slot></div>
    </div>
</template>

<script>
export default {
    name: 'ListFootBar',
    props: {
        checkedNum: Number, // 当前选中的条数
    },

    computed: {
        hasCheckedNum () {
            return typeof this.checkedNum == "number"
        }
    }
}
</script>

<style lang='scss' scoped>

    .list-foot-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
		bottom: 0; left: 0; right: 0;
        z-index: 9;
        height: 56px;
        padding-left: 16px;
        padding-right: 16px;
        background-color: white;
        box-shadow: 0px -2px 4px 0px rgba(200, 201, 204, 0.35);
    }

    .left-wrapper {
        display: flex;
        align-items: center;
    }

</style>